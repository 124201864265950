import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-is-loading-spinner',
  templateUrl: './is-loading-spinner.component.html',
  styleUrls: ['./is-loading-spinner.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressSpinnerModule
  ]
})
export class IsLoadingSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
