import { Injectable } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
import { environment } from '@env/environment';
import { IUserInfo } from "../state/user/user-state.interface";


@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  initAmplitude(id: string) {
    amplitude.init(environment.amplitudeApiKey, id, {
      defaultTracking: {
        attribution: true,
        pageViews: true,
        sessions: false,
        fileDownloads: false,
        formInteractions: false,
      },
    });
  }

  trackEvent(eventName: string, eventData?: any) {
    if (environment.production) {
      amplitude.track(eventName, eventData);
    } else {
      console.log('trackEvent', eventName, eventData);
    }
  }

  setUserIdentifier(user: IUserInfo) {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('id', user.id);
    identifyEvent.set('email', user.email);
    identifyEvent.set('full_name', user.first_name + ' ' + user.last_name);
    identifyEvent.set('phone_number', user.phone_number);
    identifyEvent.set('role', 'admin');
  }
}
