import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';
import { IEmployeeScheduleRes, ISetScheduleSlotBody, IGetCalendarAppointments, ICalendarAppointmentsRes, ICalendarAppointments, IGetCalendarAppointment } from '../state/calendar-state.interface';
import { Helpers } from '../../../entities/helpers';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../../../state/user/user-state.selector';
import { format } from 'date-fns';
import { CalendarUtilityService } from '../../../entities/calendar-utility.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarApiService {

  constructor(
    private http: HttpClient,
    private store: Store,
    private calendarUtilityService: CalendarUtilityService
  ) { }

  getEmployeeSchedule(
    { schedule_id, range_from, range_to }: { schedule_id: string; range_from: string; range_to: string; }
  ): Observable<IEmployeeScheduleRes> {
    let params = Helpers.prepareParams({ range_from, range_to });

    return this.http.get<IEmployeeScheduleRes>(`${environment.apiEndpoint}/schedules/${schedule_id}/periods`, { params });
  }

  setEmployeeSchedule(schedule_id: string, data: ISetScheduleSlotBody[]): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(`${environment.apiEndpoint}/schedules/${schedule_id}/periods`, { data });
  }

  deleteEmployeeSchedule(schedule_id: string, data: string[]): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(`${environment.apiEndpoint}/schedules/${schedule_id}/periods/clear`, { data });
  }

  getAppointments(value: IGetCalendarAppointments = {}): Observable<ICalendarAppointments[]> {
    let company_id = this.store.selectSnapshot(UserStateSelectors.companyId);
    let params = Helpers.prepareParams({ company_id, ...value });

    return this.http.get<ICalendarAppointmentsRes>(`${environment.apiEndpoint}/appointments`, { params }).pipe(
      map((res: ICalendarAppointmentsRes) => {
        return res.data.map((slot: ICalendarAppointments) => this.calendarUtilityService.prepareCalendarAppointment(slot));
      })
    );
  }

  getCalendarAppointment({ appointment_id }: IGetCalendarAppointment): Observable<ICalendarAppointments> {
    return this.http.get<{ data: ICalendarAppointments }>(`${environment.apiEndpoint}/appointments/${appointment_id}`).pipe(map(res => res.data));
  }
}
