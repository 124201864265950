import { Injectable } from '@angular/core';
import { FullWindowModalComponent } from './full-window-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalComponent: FullWindowModalComponent;

  bindModalComponent(component: FullWindowModalComponent) {
    this.modalComponent = component;
  }

  openModal(component: any, inputs?: {[key: string]: any}) {
    if (this.modalComponent) {
      return this.modalComponent.openModal(component, inputs);
    }
  }

  closeModal() {
    if (this.modalComponent) {
      this.modalComponent.close();
    }
  }

}
