import { AddAppointment } from "./appointments-state.interface";

export namespace AppointmentActions {
  export class UpdateAppointmentForm {
    static readonly type = '[Appointment State] Update Appointment Form';

    constructor(public params: {
      [key: string]: any;
    }) {}
  }

}
