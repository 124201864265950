import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';
import { IUserInfoRes, IUserInfo } from '../../state/user/user-state.interface';

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  constructor(
    private http: HttpClient
  ) { }

  getUserInfo(): Observable<IUserInfo> {
    return this.http.get<IUserInfoRes>(`${environment.apiEndpoint}/users/me`).pipe(map((res: IUserInfoRes) => res.data));
  }

}
