import { Pipe, PipeTransform } from '@angular/core';
import { set } from 'date-fns';
import { format } from 'date-fns';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: any): string {
    const today = new Date();
    const timeParts = value.split(':');

    const dateWithTime = set(today, {
      hours: parseInt(timeParts[0], 10),
      minutes: parseInt(timeParts[1], 10),
      milliseconds: 0,
    });

    return format(dateWithTime, 'HH:mm'); 
  }

}
