import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Size } from '../../entities/constant';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class AvatarComponent {
  @Input() firstName: string = null;
  @Input() lastName: string = null;
  @Input() image: string = null;
  @Input() size: string = Size.Medium;
  @Input() iconClass: string = null;

  Size: typeof Size = Size;
  shortTitle: string;

  get title() {
    return `${this.firstName?.substring(0, 1)} ${this.lastName?.substring(0, 1)}`;
  }
  
}
