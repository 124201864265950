<div class="parent-layout">
  <app-sidebar *ngIf="!(user$ | async).is_new_user"></app-sidebar>

  <div class="main-content">
    <router-outlet></router-outlet>
  </div>

  <footer class="parent-layout__footer" *ngIf="showFooter">
    <app-bottom-bar></app-bottom-bar>
  </footer>
</div>

<app-full-window-modal #modal></app-full-window-modal>
