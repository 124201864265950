import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Helpers } from '../../../entities/helpers';
import { AddAppointment, UpdateAppointment } from '../state/appointments-state.interface';
import { ICalendarAppointments, ICalendarAppointmentsAddRes } from "../../schedule/state/calendar-state.interface";

@Injectable({
  providedIn: 'root'
})
export class AppointmentsApiService {

  constructor(
    private http: HttpClient
  ) { }

  addAppointments(params: AddAppointment): Observable<ICalendarAppointmentsAddRes> {
    const url = `${environment.apiEndpoint}/appointments`;
    return this.http.post<ICalendarAppointmentsAddRes>(url, params).pipe(map((res) => res));
  }

  updateAppointments(id: string, params: UpdateAppointment): Observable<ICalendarAppointments> {
    const url = `${environment.apiEndpoint}/appointments/${id}`;
    return this.http.put<{data: any}>(url, params).pipe(map((res) => res.data));
  }

  deleteAppointment(id: string): Observable<any> {
    const url = `${environment.apiEndpoint}/appointments/${id}`;
    return this.http.delete<any>(url);
  }

  getAvailableTimeSlots(body: any): Observable<string[]> {
    const params = Helpers.prepareParams(body);
    // const params = Helpers.prepareParams({
    //   branch_id: body.branch_id,
    //   services: body.service?.id,
    //   employee_id: body.employee_id,
    //   book_date: body.book_date
    // });

    const url = `${environment.apiEndpoint}/book_dates/times`;
    return this.http.get<{data: string[]}>(url, {params}).pipe(map(res => res.data));
  }

  getAvailableDates(bookDayParams: {branch_id: string; employee_id?: string;}): Observable<string[]> {
    const params = Helpers.prepareParams(bookDayParams);

    const url = `${environment.apiEndpoint}/book_dates/dates`;
    return this.http.get<{data: string[]}>(url, {params}).pipe(map(res => res.data));
  }
}
