import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsModule } from '@ngxs/store';
import { provideEnvironmentNgxMask } from 'ngx-mask'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ParentLayoutComponent } from './components/parent-layout/parent-layout.component';
import { environment } from '@env/environment';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { DatePipe, registerLocaleData } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from './pipes/pipes.module';
import { CompanyState } from './pages/company/state/company.state';
import { LoadingState } from './state/loading/loading.state';
import { ToastrModule } from 'ngx-toastr';
import { DetailsPageService } from './entities/details-page.service';
import { MatDialogModule } from '@angular/material/dialog';
import { BottomBarComponent } from './components/bottom-bar/bottom-bar.component';
import localeUk from '@angular/common/locales/uk';
import { CalendarState } from './pages/schedule/state/calendar.state';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { UserState } from './state/user/user.state';
import { MatSelectModule } from '@angular/material/select';
import { IsLoadingSpinnerComponent } from './shared/is-loading-spinner/is-loading-spinner.component';
import { AvatarComponent } from './shared/avatar/avatar.component';
import { DeviceDetectorService } from './entities/device-detector.service';
import { FullWindowModalComponent } from './shared/full-window-modal/full-window-modal.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateWebpackLoader } from './translation/translateWebpackLoader';
import { ProfileMenuComponent } from "./shared/profile-menu/profile-menu.component";
import { NotFoundComponent } from './components/not-found/not-found.component';
import { EmptyStateComponent } from './shared/empty-state/empty-state.component';
import { AppointmentsState } from './pages/appointments/state/appointments.state';
import { LinkFollowButton } from "./shared/link-follow-button/link-follow-button";
import { BranchWidgetLinkButton } from "./shared/branch-widget-link-button/branch-widget-link-button";

registerLocaleData(localeUk);

@NgModule({
  declarations: [
    AppComponent,
    ParentLayoutComponent,
    SidebarComponent,
    BottomBarComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    AvatarComponent,
    IsLoadingSpinnerComponent,
    EmptyStateComponent,
    NgScrollbarModule,
    MatExpansionModule,
    MatSelectModule,
    HttpClientModule,
    MatDialogModule,
    MatSidenavModule,
    FullWindowModalComponent,
    MatMenuModule,
    PipesModule,
    MatIconModule,
    AuthModule.forRoot({
      domain: `${environment.auth0Domain}`,
      issuer: `${environment.auth0Domain}`,
      clientId: `${environment.auth0ClientId}`,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: `${environment.audience}`,
      },
      httpInterceptor: {
        allowedList: [
          `${environment.apiEndpoint}/*`
        ]
      }
    }),
    NgxsFormPluginModule.forRoot(),
    ToastrModule.forRoot(
      {
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        iconClasses: {
          error: 'toast-error icon-alert-triangle',
          info: 'toast-info',
          success: 'toast-success icon-checked-circle',
          warning: 'toast-warning'
        },
      }
    ),
    TranslateModule.forRoot({
      defaultLanguage: 'uk',
      loader: {
        provide: TranslateLoader,
        useFactory: (translateFactory),
      },
    }),
    NgxsModule.forRoot([
      CompanyState,
      LoadingState,
      CalendarState,
      UserState,
      AppointmentsState
    ], {
      developmentMode: !environment.production,
      selectorOptions: {
        injectContainerState: false
      }
    }),
    ProfileMenuComponent,
    LinkFollowButton,
    BranchWidgetLinkButton,
  ],
  providers: [
    DatePipe,
    DetailsPageService,
    DeviceDetectorService,
    provideEnvironmentNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'uk'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function translateFactory() {
  return new TranslateWebpackLoader();
}
