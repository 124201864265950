import { Component, Inject, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-full-window-modal',
  templateUrl: './full-window-modal.component.html',
  styleUrls: ['./full-window-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ],
  providers: [ModalService]
})
export class FullWindowModalComponent {
  @ViewChild('modalContent', {static: true, read: ViewContainerRef }) container: ViewContainerRef;
  isOpen: boolean = false;

  constructor (
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnDestroy() {
    this.container.clear();
  }

  openModal(component: Type<any>, inputs: {[key: string]: any}) {
    this.isOpen = true;
    this.container.clear();
    const compRef = this.container.createComponent(component);

    // Input property
    Object.keys(inputs).forEach(key => {
      compRef.instance[key] = inputs[key];
    });

    // Subscribe to the closeModal event
    if (compRef.instance['closeModal']) {
      compRef.instance['closeModal'].subscribe(() => {
        this.close();
      });
    }
    this.document.body.classList.add('no-scroll');
    return compRef;
  }

  close() {
    this.container.clear();
    this.document.body.classList.remove('no-scroll');
    this.isOpen = false;
  }

}
