import { Selector } from "@ngxs/store";
import { COMPANY_STATE_TOKEN, ICompanyState, Branch, Company, BusinessArea } from './company-state.interface';

export class CompanyStateSelectors {
  @Selector([COMPANY_STATE_TOKEN])
  static allBranches(state: ICompanyState): Branch[] {
    return [...state.branches];
  }

  @Selector([COMPANY_STATE_TOKEN])
  static firstBranch(state: ICompanyState): Branch {
    return state.branches[0];
  }

  @Selector([COMPANY_STATE_TOKEN])
  static branchDetails(state: ICompanyState): Branch {
    return {...state.branchDetails};
  }

  @Selector([COMPANY_STATE_TOKEN])
  static company(state: ICompanyState): Company {
    return state.company;
  }

  @Selector([COMPANY_STATE_TOKEN])
  static businessAreas(state: ICompanyState): BusinessArea[] {
    return state.businessAreas;
  }

  @Selector([COMPANY_STATE_TOKEN])
  static totalNumberOfBranches(state: ICompanyState): number {
    return state.totalNumberOfBranches;
  }
}
