import { StateToken } from '@ngxs/store';
import { Service } from '../../../entities/app.model';
import { Employee } from '../../employees/state/employees-state.interface';
import { Client } from '../../clients/state/clients-state.interface';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

export const APPOINTMENTS_STATE_TOKEN = new StateToken<IAppointmentsState>('AppointmentsState');

export interface IAppointmentsState {
  newAppointmentForm: {
    model?: FormModel;
  };
}

export interface FormModel {
  services: Service[];
  employee: Employee;
  date: AppointmentDate;
  client: Client;
  totalPrice: number;
  totalDuration: number;
  description: string;
}

export interface ISelectBaseState extends FormModel {
  appointmentId: string;
}

export interface AppointmentDate {
  timeSlot: string; // 14:30 - 15:30
  date: Date;
}

export interface AddAppointment {
  company_id: string;
  branch_id: string;
  client_id: string;
  employee_id: string;
  services: string[];
  start_at: string;
  price: number;
  duration: number;
  description?: string;
}

export interface UpdateAppointment {
  branch_id?: string;
  client_id?: string;
  employee_id?: string;
  services?: string[];
  start_at?: string;
  price?: number;
  duration?: number;
  description?: string;
  status?: string;
}

export interface Resolve<T> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T> | Promise<T>;
}
