import { Pipe, PipeTransform } from '@angular/core';
import { DurationPipe } from './duration.pipe';

@Pipe({
  name: 'conditional'
})
export class ConditionalPipe implements PipeTransform {

  transform(value: any, pipeName?: string,): any {
    if (!pipeName) return value;

    switch (pipeName) {
      case 'duration':
        const durationPipe = new DurationPipe();
        return durationPipe.transform(value);
      default:
        return value;
    }
  }

}
