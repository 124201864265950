import { Selector } from "@ngxs/store";
import { USER_STATE_TOKEN, IUserState, IUserInfo } from './user-state.interface';

export class UserStateSelectors {
  @Selector([USER_STATE_TOKEN])
  static user(state: IUserState): IUserInfo {
    return state.user;
  }

  @Selector([USER_STATE_TOKEN])
  static companyId(state: IUserState): string {
    return state.user.company_id;
  }
}
