import { Component, OnInit } from '@angular/core';
import { Path } from '../../app-routing.config';
import { Router } from "@angular/router";
import { AmplitudeService } from "../../entities/amplitude.service";
import { UserStateSelectors } from "../../state/user/user-state.selector";
import { Store } from "@ngxs/store";

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {
  Path = Path;

  constructor(
    private store: Store,
    private router: Router,
    private amplitudeService: AmplitudeService,
  ) { }

  ngOnInit() {
  }

  trackEventOpenAppointmentPage(): void {
    this.amplitudeService.trackEvent(
      'create_appointment_clicked',
      {
        company_id: this.store.selectSnapshot(UserStateSelectors.companyId),
        location: this.router.url,
        from_sidebar: true,
      });
  }

  goToNewAppointmentPage(): void {
    this.trackEventOpenAppointmentPage();
    this.router.navigateByUrl(`${Path.BOOKING}`);
  }

}
