import { StateToken } from "@ngxs/store";
import { IPaginationRes } from '../../entities/app.model';
import { Branch } from '../../pages/company/state/company-state.interface';
import { Position, Role } from "../../pages/employees/state/employees-state.interface";

export const USER_STATE_TOKEN = new StateToken<IUserState>('UserState');

export interface IUserState {
  user: IUserInfo;
}

export interface IUserInfo {
  id: string;
  user_id: string;
  company_id: string;
  schedule_id: string;
  position_id: string;
  role_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  avatar: string;
  is_new_user: boolean;
  position: Position;
  role: Role;
  branches: Branch[];
}

export interface IUserInfoRes extends IPaginationRes {
  data: IUserInfo;
}
