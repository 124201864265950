import { Component, OnInit } from '@angular/core';
import { SidebarToggleService } from '../../entities/sidebar-toggle.service';
import { Observable } from 'rxjs';
import { Path } from 'src/app/app-routing.config';
import { Select, Store } from '@ngxs/store';
import { UserStateSelectors } from '../../state/user/user-state.selector';
import { IUserInfo } from '../../state/user/user-state.interface';
import { Router } from "@angular/router";
import { AmplitudeService } from "../../entities/amplitude.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Select(UserStateSelectors.user) user$: Observable<IUserInfo>;
  isSidebarClose$: Observable<boolean> = this.sidebarToggleService.isToggled$;
  Path = Path;

  constructor(
    private sidebarToggleService: SidebarToggleService,
    private store: Store,
    private router: Router,
    private amplitudeService: AmplitudeService,
  ) { }

  ngOnInit() {
  }

  trackEventOpenAppointmentPage(): void {
    this.amplitudeService.trackEvent(
      'create_appointment_clicked',
      {
        company_id: this.store.selectSnapshot(UserStateSelectors.companyId),
        location: this.router.url,
        from_sidebar: true,
      });
  }

  goToNewAppointmentPage(): void {
    this.trackEventOpenAppointmentPage();
    this.router.navigateByUrl(`${Path.BOOKING}`);
  }

  toggleSidebar(): void {
    this.sidebarToggleService.toggleSidebar();
  }

}
