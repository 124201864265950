import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { AppointmentActions } from './appointments-state.action';
import { APPOINTMENTS_STATE_TOKEN, IAppointmentsState } from './appointments-state.interface';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { AppointmentsApiService } from '../services/appointments-api.service';
import { Observable } from 'rxjs';

@State<IAppointmentsState>({
  name: APPOINTMENTS_STATE_TOKEN,
  defaults: {
    newAppointmentForm: {
      model: {
        client: null,
        date: null,
        employee: null,
        services: [],
        totalPrice: null,
        totalDuration: null,
        description: null
      }
    }
  }
})
@Injectable()
export class AppointmentsState {
  constructor(
    private appointmentsApiService: AppointmentsApiService
  ) { }

  @Action(AppointmentActions.UpdateAppointmentForm)
  updateAppointmentForm(
    ctx: StateContext<IAppointmentsState>,
    action: AppointmentActions.UpdateAppointmentForm
  ): void {
    const formValue = ctx.getState().newAppointmentForm.model;

    ctx.dispatch(new UpdateFormValue({
      path: `AppointmentsState.newAppointmentForm`,
      value: {
        ...formValue,
        ...action.params
      },
    }));
  }
}
