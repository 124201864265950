<div
  class="avatar"
  [ngClass]="{
    '-xs': size == Size.XS,
    '-xs-wide': size == Size.XsWide,
    '-small': size == Size.Small,
    '-large': size == Size.Large
  }"
>
  <ng-container *ngIf="image; else noImage">
    <img [src]="image">
  </ng-container>
</div>

<ng-template #noImage>
  <ng-container *ngIf="firstName && lastName; else defaultIcon">
    <span>{{ title }}</span>
  </ng-container>
</ng-template>

<ng-template #defaultIcon>
  <i class="avatar-icon" [ngClass]="iconClass"></i>
</ng-template>
