<div class="modal-action-dialog">
  <div class="modal-action-dialog__title">
    <span>
      {{modalData.config.headerTitle}}
    </span>

    <button class="close-btn" (click)="close()">
      <i class="icon-x-close"></i>
    </button>
  </div>

  <div class="modal-action-dialog__content">
    <i [class]="modalData.config.content.icon"></i>
    <span class="modal-action-dialog__content__title">
      {{ modalData.config.content.title }}
    </span>
    <span class="modal-action-dialog__content__description">
      {{ modalData.config.content.description }}
    </span>
  </div>

  <div class="modal-action-dialog__actions">
    <button (click)="close()" class="outline">
      Залишитись
    </button>
    <button [mat-dialog-close]="modalData.data" class="destructive">
      {{ modalData.config.buttonTitle }}
    </button>
  </div>
</div>
