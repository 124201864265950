import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserStateSelectors } from './state/user/user-state.selector';
import { Observable, filter } from 'rxjs';
import { IUserInfo } from './state/user/user-state.interface';
import { AuthService } from '@auth0/auth0-angular';
import { AmplitudeService } from "./entities/amplitude.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'natodi';

  @Select(UserStateSelectors.user) user$: Observable<IUserInfo>;
  // @Select(CompanyStateSelectors.allBranches) allBranches$: Observable<Branch[]>;

  constructor(
    public auth: AuthService,
    private amplitudeService: AmplitudeService
    // public profileApiService: ProfileApiService
  ) { }

  ngOnInit() {
    this.user$
      .pipe(filter(val => val !== null))
      .subscribe(user => {
        if (user) {
          this.amplitudeService.initAmplitude(user.id);
          this.amplitudeService.setUserIdentifier(user);
        }
      });
  }

}
