import { Component, ElementRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Select } from "@ngxs/store";
import { UserStateSelectors } from "../../state/user/user-state.selector";
import { Observable } from "rxjs";
import { IUserInfo } from "../../state/user/user-state.interface";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { Path } from 'src/app/app-routing.config';
import { AvatarComponent } from "../avatar/avatar.component";


@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent
  ],
})
export class ProfileMenuComponent {
  @Select(UserStateSelectors.user) user$: Observable<IUserInfo>;
  isOpen = false;
  Path = Path;

  constructor(
    private router: Router,
    private auth: AuthService,
    private eRef: ElementRef
  ) {
  }

  ngOnInit() {
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  goToProfile(id: string) {
    this.isOpen = false;
    this.router.navigate([`${Path.EMPLOYEES}`, 'edit', id]);
  }

  openTelegramBot() {
    window.open('https://t.me/natodi_support_bot', '_blank');
  }

  logout() {
    this.isOpen = false;
    this.auth.logout();
  }

}
