import { Injectable } from '@angular/core';
import { Action, Actions, NgxsOnInit, ofActionSuccessful, State, StateContext, Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { UserActions } from './user-state.action';
import { IUserState, USER_STATE_TOKEN, IUserInfo } from './user-state.interface';
import { ProfileApiService } from '../../pages/profile/profile-api.service';
import { Router } from '@angular/router';
import { Path } from '../../app-routing.config';
import { CompanyActions } from 'src/app/pages/company/state/company-state.action';

@State<IUserState>({
  name: USER_STATE_TOKEN,
  defaults: {
    user: null
  }
})
@Injectable()
export class UserState implements NgxsOnInit {
  constructor(
    private store: Store,
    private profileApiService: ProfileApiService,
    private actions$: Actions,
    private router: Router
  ) { }

  ngxsOnInit(ctx: StateContext<IUserState>): void {
    this.store.dispatch(new UserActions.GetUserInfo());

    this.actions$.pipe(
        ofActionSuccessful(UserActions.GetUserInfo)
      ).subscribe(() => {
        const user = ctx.getState().user;
        if (user.is_new_user) {
          const url = user.company_id ? `${Path.COMPANY}/branch` : `${Path.COMPANY}/edit`;
          this.router.navigate([url], {
            state: {
              ...(user.company_id && { companyId: user.company_id })
            }
          });
        } else {
          this.store.dispatch(new CompanyActions.GetBranches({}));
        }
      });
  }

  @Action(UserActions.GetUserInfo)
  getUserInfo(
    ctx: StateContext<IUserState>,
    action: UserActions.GetUserInfo
  ): Observable<IUserInfo> {
    return this.profileApiService.getUserInfo()
      .pipe(
        tap((res: IUserInfo) => {
          ctx.patchState({
            user: res
          });
        })
      );
  }
}
