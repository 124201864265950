<ng-container *ngIf="widgetLink">
  <div class="link-follow-button">
    <button class="copy-button primary" (click)="copyWidgetLink()">
      <i class="icon-copy"></i>
    </button>

    <div class="navigation-wrapper"  (click)="navigateToWidget()">
      <div class="content">
        <span class="title">
          Посилання на онлайн запис
        </span>
        <span class="description">
          {{ widgetLink || 'Не вказано' }}
        </span>
      </div>

      <div class="icon-wrapper">
        <i class="icon-arrow-left"></i>
      </div>
    </div>
  </div>
</ng-container>
