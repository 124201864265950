import { NgModule } from '@angular/core';
import { StatusPipe } from './status.pipe';
import { FromUppercasePipe } from './from-uppercase.pipe';
import { TimeTransformPipe } from './time-transform.pipe';
import { DurationPipe } from './duration.pipe';
import { ConditionalPipe } from './conditional.pipe';
import { FilterPipe } from './filter.pipe';
import { SortPipe } from './sort.pipe';
import { PhoneTransformPipe } from "./phone-transform.pipe";
import { TimeFormatPipe } from './time-format.pipe';

const pipes = [
  StatusPipe,
  FromUppercasePipe,
  TimeTransformPipe,
  DurationPipe,
  ConditionalPipe,
  FilterPipe,
  SortPipe,
  PhoneTransformPipe,
  TimeFormatPipe
];

@NgModule({
  declarations: pipes,
  exports: pipes
})
export class PipesModule { }
