import { IGetBranchesParams } from './company-state.interface';

export namespace CompanyActions {
  export class GetCompany {
    static readonly type = '[CompanyState] Get Companies';
  }

  export class GetBusinessArea {
    static readonly type = '[CompanyState] Get Business Areas';

    constructor(public params: {
      searchValue: string;
    }) {}
  }

  export class EditCompany {
    static readonly type = '[CompanyState] Edit Company';

    constructor(public params: {
      body: {
        business_area_id: string;
        title: string;
      };
    }) {}
  }
 
  export class GetBranches {
    static readonly type = '[CompanyState] Get Branches';

    constructor(public params: IGetBranchesParams) {}
  }

  export class GetBranch {
    static readonly type = '[CompanyState] Get Branch';
  
    constructor(public params: {
      branchId: string;
    }) {}
  }

  export class UpdateBranch {
    static readonly type = '[CompanyState] Update Branches';
  
    constructor(public params: {
      branchId: string;
      body: any;
    }) {}
  }

  export class AddBranch {
    static readonly type = '[CompanyState] Add New Branches';
  
    constructor(public params: {
      body: any;
    }) {}
  }

  export class DeleteBranch {
    static readonly type = '[CompanyState] Delete Branches';
  
    constructor(public params: {
      id: string;
    }) {}
  }

  export class UpdateBranchToService {
    static readonly type = '[CompanyState] Connect Branch To Service';

    constructor(public params: {
      branchId: string;
      serviceId: string;
      checked: boolean;
    }) {}
  }

  export class UpdateBranchToEmployee {
    static readonly type = '[CompanyState] Connect Branch To Employee';

    constructor(public params: {
      branchId: string;
      employeeId: string;
      checked: boolean;
    }) {}
  }
}
