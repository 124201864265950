<div class="modal" [ngClass]="{'visible': isOpen}">
    <div class="modal-content">
      <div class="header">
        <button
          class="default"
          (click)="close()">
          <i class="icon-arrow-left"></i>
        </button>
      </div>
    <ng-container #modalContent></ng-container>
  </div>
</div>
