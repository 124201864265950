import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeTransform'
})
export class TimeTransformPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const result = [];

    for (let period of value.split('-')) {
      const timeSlot = period.split(':');
      if (timeSlot.length > 2) {
        timeSlot.pop();
      }
      result.push(timeSlot.join(':'));
    }

    return result.join('-');
  }

}
