import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneTransform'
})
export class PhoneTransformPipe implements PipeTransform {

  transform(value: string, countryCode = '38'): string {
    if (!value || typeof value !== 'string') {
      return '';
    }

    const cleanedValue = value.replace(/\D/g, ''); // Remove non-digit characters
    const phoneNumberLength = cleanedValue.length;

    if (phoneNumberLength < 10) {
      return value; // Not enough digits for a valid phone number
    }

    if (phoneNumberLength === 10) {
      return `+${countryCode}${cleanedValue.slice(0, 1)} ${cleanedValue.slice(1, 3)} ${cleanedValue.slice(3, 6)} ${cleanedValue.slice(6)}`;
    } else {
      return value;
    }
  }

}
