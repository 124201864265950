<div class="p-30 img-upload-dialog">
  <h4 mat-dialog-title>
    {{data.file ? 'Зберегти обране фото ?' : 'Видалити фото ?'}}
    <button class="close-btn bg-transparent p-0 border-none" (click)="close()">
      <i class="flaticon-close"></i>
    </button>
  </h4>
  <div mat-dialog-actions class="d-md-flex justify-content-center align-items-center img-upload-dialog_content">
    <img *ngIf="data.file" [src]="data?.file" class="rounded-circle me-20" width="200" alt="user">

    <button
      *ngIf="!data.file"
      mat-button
      (click)="uploadAvatar()"
      class="border-none natodi fw-semibold  mat-unthemed ps-30 pe-30 pt-12 pb-12 cursor-pointer">
      Видалити аватар
    </button>

    <button
      *ngIf="data.file"
      mat-raised-button
      (click)="uploadAvatar()"
      class="natodi upload-button">
      Завантажити аватар
    </button>
  </div>
</div>
