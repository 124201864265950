export enum Size {
  XS = 'xs',
  XsWide = 'xs-wide',
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export enum AppointmentStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  InProgress = 'in_progress',
  Created = 'created'
}

export enum ToggleButtonShape {
  Circle = 'circle',
  Input = 'input'
}
