import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {
  private isMobileSubject = new BehaviorSubject<boolean>(this.checkDevice());

  constructor() {
    this.handleResize();
  }

  // isMobile(): boolean {
  //   return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // }

  isMobile(): Observable<boolean> {
    return this.isMobileSubject.asObservable();
  }

  isMobileValue(): boolean {
    return this.isMobileSubject.value;
  }

  private checkDevice() {
    return window.innerWidth < 768; // or any other logic you prefer
  }

  private handleResize() {
    window.addEventListener('resize', () => {
      this.isMobileSubject.next(this.checkDevice());
    });
  }

}
