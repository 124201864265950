import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { CompanyActions } from './company-state.action';
import { ICompanyState, COMPANY_STATE_TOKEN, Branch, Company, BusinessArea, IBranchesResponse } from './company-state.interface';
import { CompanyApiService } from '../service/company-api.service';
import { patch, updateItem } from '@ngxs/store/operators';
import { SetLoadingState } from '../../../state/loading/setLoadingState';

@State<ICompanyState>({
  name: COMPANY_STATE_TOKEN,
  defaults: {
    company: null,
    branches: [],
    branchDetails: null,
    totalNumberOfBranches: null,
    businessAreas: [],
  }
})
@Injectable()
export class CompanyState implements NgxsOnInit {
  constructor(
    private store: Store,
    private companyApiService: CompanyApiService
  ) {}

  ngxsOnInit(ctx: StateContext<ICompanyState>): void {
    // this.store.dispatch(new CompanyActions.GetBranches({}));
  }

  @Action(CompanyActions.GetCompany)
  getCompany(
    ctx: StateContext<ICompanyState>,
    action: CompanyActions.GetCompany
  ): Observable<Company> {

    return this.companyApiService.getCompany()
      .pipe(
        tap((res: Company) => {
          ctx.patchState({
            company: res,
          });
      })
    );
  }

  @Action(CompanyActions.EditCompany)
  editCompany(
    ctx: StateContext<ICompanyState>,
    action: CompanyActions.EditCompany
  ): Observable<{business_area_id: string; title: string;}> {
    const { body } = action.params;

    return this.companyApiService.editCompany(body);
  }

  @Action(CompanyActions.GetBusinessArea)
  getBusinessArea(
    ctx: StateContext<ICompanyState>,
    action: CompanyActions.GetBusinessArea
  ): Observable<BusinessArea[]> {
    const { searchValue } = action.params;

    return this.companyApiService.getBusinessArea(searchValue)
      .pipe(
        tap((res: BusinessArea[]) => {
          ctx.patchState({
            businessAreas: res,
          });
      })
    );
  }

  @Action(CompanyActions.GetBranches)
  getBranches(
    ctx: StateContext<ICompanyState>,
    action: CompanyActions.GetBranches
  ): Observable<IBranchesResponse> | void {
    const {
      branchId,
      search,
      limit,
      offset
    } = action.params;

    return this.companyApiService.getAllBranches({branchId, search, limit, offset})
      .pipe(
        tap((res: IBranchesResponse) => {
          ctx.patchState({
            branches: res.data,
            totalNumberOfBranches: res.pagination.total
          });
      })
    );
  }

  @Action(CompanyActions.GetBranch)
  getBranch(
    ctx: StateContext<ICompanyState>,
    action: CompanyActions.GetBranch
  ): Observable<Branch> {
    const { branchId } = action.params;

    return this.companyApiService.getBranch(branchId)
      .pipe(
        tap((res: Branch) => {
          ctx.patchState({
            branchDetails: {...res}
          });
      })
    );
  }

  @Action(CompanyActions.UpdateBranch)
  updateBranch(
    ctx: StateContext<ICompanyState>,
    action: CompanyActions.UpdateBranch
  ): Observable<Branch> {
    const { branchId, body } = action.params;

    return this.companyApiService.updateBranch(branchId, body)
      .pipe(
        tap((res: Branch) => {
          ctx.setState(
            patch<ICompanyState>({
              branches: updateItem<Branch>(
                branch => branch.id === branchId,
                {...res}
              )
            })
          );
        })
      );
  }

  @Action(CompanyActions.AddBranch)
  addBranch(
    ctx: StateContext<ICompanyState>,
    action: CompanyActions.AddBranch
  ): Observable<Branch> {
    const { body } = action.params;

    return this.companyApiService.addBranch(body);
  }

  @Action(CompanyActions.DeleteBranch)
  deleteBranch(
    ctx: StateContext<ICompanyState>,
    action: CompanyActions.DeleteBranch
  ): Observable<Branch> {
    const { id } = action.params;

    return this.companyApiService.deleteBranch(id)
      .pipe(
        tap((res: Branch) => {
          ctx.patchState({
            branchDetails: null
          });
        })
      );
  }

  @Action(CompanyActions.UpdateBranchToService)
  updateBranchToService(
    ctx: StateContext<ICompanyState>,
    action: CompanyActions.UpdateBranchToService
  ): Observable<any> {
    const {branchId, serviceId, checked} = action.params;

    if (checked) {
      return this.companyApiService.connectBranchToService(branchId, serviceId);
    } else {
      return this.companyApiService.deleteBranchToService(branchId, serviceId);
    }
  }

  @Action(CompanyActions.UpdateBranchToEmployee)
  updateBranchToEmployee(
    ctx: StateContext<ICompanyState>,
    action: CompanyActions.UpdateBranchToEmployee
  ): Observable<any> {
    const {branchId, employeeId, checked} = action.params;

    if (checked) {
      return this.companyApiService.connectBranchToEmployee(branchId, employeeId);
    } else {
      return this.companyApiService.deleteBranchToEmployee(branchId, employeeId);
    }
  }
}
