import { of } from 'rxjs';
import UkTranslation from './i18n/uk';

export class TranslateWebpackLoader {
  private translations = {
    uk: <any>UkTranslation,
  };

  public getTranslation(lang: string): any {
    let translation = this.translations.uk;

    if (this.translations[lang]) {
      translation = this.translations[lang];
    }

    return of(translation);
  }
}
