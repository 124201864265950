import { IGetEmployeesParams, IGetEmployeeDetailsParams } from './employees-state.interface';

export namespace EmployeesActions {
  export class GetAll {
    static readonly type = '[EmployeesState] Get All Employee';

    constructor(public params: IGetEmployeesParams) {}
  }

  export class SetSelectedBranch {
    static readonly type = '[EmployeesState] Set Selected Branch';

    constructor(public params: {
      branchId: string | number
    }) {}
  }

  export class SetCurrentPageIndex {
    static readonly type = '[EmployeesState] Set Current Page Index';

    constructor(public params: {
      currentPageIndex: number
    }) {}
  }

  export class ClearEmployees {
    static readonly type = '[EmployeesState] Clear All Employees';
  }

  export class GetDetails {
    static readonly type = '[EmployeesState] Get Details Employee';

    constructor(public params: IGetEmployeeDetailsParams) {}
  }

  export class Add {
    static readonly type = '[EmployeesState] Add New Employee';

    constructor(public params: {
      body: any;
    }) {}
  }

  export class Delete {
    static readonly type = '[EmployeesState] Delete Employee';

    constructor(public params: {
      id: string;
    }) {}
  }

  export class Edit {
    static readonly type = '[EmployeesState] Edit Employee';

    constructor(public params: {
      employeeId: string;
      body: any;
    }) {}
  }

  export class ClearState {
    static readonly type = '[EmployeesState] Clear State Data';

    constructor(public params: {
      property: string
    }) {}
  }

  export class UpdateServiceToEmployee {
    static readonly type = '[EmployeesState] Connect Service To Employee';

    constructor(public params: {
      employeeId: string;
      serviceId: string;
      checked: boolean;
    }) {}
  }

  export class GetPositions {
    static readonly type = '[EmployeesState] Get Positions';

    constructor(public params: {
      searchValue: string;
    }) {}
  }
}
