import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-delete-modal-action',
  templateUrl: './delete-modal-action.component.html',
  styleUrls: ['./delete-modal-action.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule]
})
export class DeleteModalActionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteModalActionComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(false);
  }

}
