import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarToggleService {
  private isBottomBarVisible = new BehaviorSubject<boolean>(true);
  public isBottomBarVisible$: Observable<boolean> = this.isBottomBarVisible.asObservable();

  private isToggled = new BehaviorSubject<boolean>(false);
  isToggled$: Observable<boolean> = this.isToggled.asObservable();

  toggleSidebar(): void {
    this.isToggled.next(!this.isToggled.value);
  }

  toggleBottomBar(value: boolean): void {
    this.isBottomBarVisible.next(value);
  }

}
