import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ImgUploadDialogComponent } from '../shared/img-upload-dialog/img-upload-dialog.component';
import { DeleteModalActionComponent } from '../shared/delete-modal-action/delete-modal-action.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DetailsPageService {

  constructor(
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) { }

  onAvatarChange(file: any, identifier: string) {
    const dialogRef = this.dialog.open(ImgUploadDialogComponent, {
      width: '600px',
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '100ms',
      data: {
        file,
        employeeId: identifier
      }
    });

    return dialogRef.afterClosed().subscribe((succeed) => {
      if (succeed) {
        this.toastr.success(null, 'Збережено');
      }
    });
  }

  openDeleteModal(data: any): Observable<any> {
    const dialogRef = this.dialog.open(DeleteModalActionComponent, {
      panelClass: 'delete-action-container',
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '100ms',
      data: data
    });

    return dialogRef.afterClosed();
  }

}
