import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: any[], prop = null, order: string = 'asc'): string[] {
    if (!value) {
      return [];
    }

    const sortedArray = value.sort((a, b) => {
      if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
        return order === 'asc' ? -1 : 1;
      } else if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
        return order === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });

    return sortedArray;
  }

}
