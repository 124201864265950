import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ParentLayoutComponent } from './components/parent-layout/parent-layout.component';
import { Path } from './app-routing.config';
import { AuthGuard } from '@auth0/auth0-angular';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: ParentLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: Path.COMPANY,
        loadChildren: () => import('src/app/pages/company/company.module').then(m => m.CompanyModule),
      },
      {
        path: Path.SERVICES,
        loadChildren: () => import('src/app/pages/services/services.module').then(m => m.ServicesModule)
      },
      {
        path: Path.CLIENTS,
        loadChildren: () => import('src/app/pages/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: Path.EMPLOYEES,
        loadChildren: () => import('src/app/pages/employees/employees.module').then(m => m.EmployeesModule)
      },
      {
        path: Path.CALENDAR,
        loadChildren: () => import('src/app/pages/schedule/schedule.module').then(m => m.ScheduleModule)
      },
      {
        path: Path.TIMETABLE,
        loadChildren: () => import('src/app/pages/timetable/timetable.module').then(m => m.TimetableModule)
      },
      {
        path: Path.BOOKING,
        loadChildren: () => import('src/app/pages/appointments/appointments.module').then(m => m.AppointmentsModule),
        data: { showFooter: false }
      },
      {
        path: Path.PROFILE,
        loadChildren: () => import('src/app/pages/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: Path.MENU,
        loadChildren: () => import('src/app/pages/menu/menu.module').then(m => m.MenuModule)
      },
      { path: '', redirectTo: Path.CALENDAR, pathMatch: 'full' }
    ]
  },
  { path: '**', component: NotFoundComponent },
  {
    path: 'natodi-landing',
    loadComponent: () => import('src/app/components/natodi-landing/natodi-landing.component').then(m => m.NatodiLandingComponent)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true,
      scrollOffset: [0, 0]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
