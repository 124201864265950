import { IGetCalendarAppointments, ISetScheduleSlotBody } from "./calendar-state.interface";
import { Employee } from '../../employees/state/employees-state.interface';

export namespace CalendarActions {
  export class FetchEmployeeSchedule {
    static readonly type = '[CalendarState] Fetch Employee Schedule';

    constructor(public params: {
      schedule_id: string;
      range_from: string;
      range_to: string;
    }) {}
  }

  export class SetEmployeeSchedule {
    static readonly type = '[CalendarState] Set/Update Employee Schedule';

    constructor(public params: {
      schedule_id: string;
      data:ISetScheduleSlotBody[];
    }) {}
  }

  export class DeleteEmployeeSchedule {
    static readonly type = '[CalendarState] Delete Employee Schedule';

    constructor(public params: {schedule_id: string, data: string[]}) {}
  }

  export class SetClientAppointments {
    static readonly type = '[CalendarState] Set Client Appointments';

    constructor(public params: IGetCalendarAppointments) {}
  }

  export class SetRecordsAppointments {
    static readonly type = '[CalendarState] Set Records Appointments';

    constructor(public params: IGetCalendarAppointments) {}
  }

  export class SetCalendarAppointments {
    static readonly type = '[CalendarState] Set Calendar Appointments';

    constructor(public params: IGetCalendarAppointments) {}
  }

  export class GetCalendarEmployees {
    static readonly type = '[CalendarState] Get Calendar Employees';

    constructor(public params: {
      limit: number;
      offset: number;
      bookable: boolean;
      day: string; // format: YYYY-MM-DD
    }) {}
  }

  export class UpdateTimeCalendarAppointment {
    static readonly type = '[CalendarState] Update Time Calendar Appointment';

    constructor(public params: {
      eventId: string;
      start: string;
      end: string;
      employeeId?: string;
    }) {}
  }

  // export class UpdateUserCalendarAppointment {
  //   static readonly type = '[CalendarState] Update User Calendar Appointment';

  //   constructor(public params: {
  //     eventId: string;
  //     employeeId: string;
  //   }) {}
  // }

}
