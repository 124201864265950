import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { ILoadingState } from './loading.models';
import { SetLoadingState } from './setLoadingState';
import { Injectable } from "@angular/core";

export const LOADING_STATE_TOKEN = new StateToken<ILoadingState>('loadingState');

@State<ILoadingState>({
  name: LOADING_STATE_TOKEN,
  defaults: {
    isLoading: false,
  }
})
@Injectable()
export class LoadingState {
  constructor() {
  }

  @Action(SetLoadingState)
  setLoadingState(ctx: StateContext<ILoadingState>, action: SetLoadingState): void {
    ctx.patchState({
      isLoading: action.isLoading
    });
  }
}

