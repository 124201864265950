import { Component, ViewChild, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { FullWindowModalComponent } from '../../shared/full-window-modal/full-window-modal.component';
import { ModalService } from '../../shared/full-window-modal/modal.service';
import { SidebarToggleService } from '../../entities/sidebar-toggle.service';
import { Observable } from 'rxjs/internal/Observable';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserStateSelectors } from '../../state/user/user-state.selector';
import { IUserInfo } from '../../state/user/user-state.interface';

@Component({
  selector: 'app-parent-layout',
  templateUrl: './parent-layout.component.html',
  styleUrls: ['./parent-layout.component.scss']
})
export class ParentLayoutComponent implements OnInit, AfterViewInit {
  @Select(UserStateSelectors.user) user$: Observable<IUserInfo>;
  @ViewChild('modal') modalComponent: FullWindowModalComponent;

  isBottomBarVisible$: Observable<boolean> = this.sidebarToggleService.isBottomBarVisible$;
  showFooter: boolean = true;
  constructor(
    private modalService: ModalService,
    private sidebarToggleService: SidebarToggleService,
    private elRef: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: any) => {
        this.updateFooterVisibility();
        this.elRef.nativeElement.scrollTo({top: 0});
      });

    this.updateFooterVisibility();
  }

  ngAfterViewInit() {
    this.modalService.bindModalComponent(this.modalComponent);
  }
  

  isFooterHiddenRoute(url: string): boolean {
    const hiddenFooterRoutes = [
      '/booking'
    ];
    return hiddenFooterRoutes.includes(url);
  }

  updateFooterVisibility() {
    let currentRoute = this.activatedRoute.root;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    this.showFooter = currentRoute.snapshot.data['showFooter'] !== false;
  }

}
