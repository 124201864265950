import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { ICalendarAppointments } from '../pages/schedule/state/calendar-state.interface';

@Injectable({
  providedIn: 'root'
})
export class CalendarUtilityService {

  constructor() { }

  prepareCalendarAppointment(appointment: ICalendarAppointments) {
    const start = new Date(`${appointment.start_at}`);
    const end = new Date(`${appointment.end_at}`);
    let startTime = format(start, 'HH:mm');
    let endTime = format(end, 'HH:mm');

    return {
      ...appointment,
      title: `${startTime}-${endTime}`
    };
  }

}
