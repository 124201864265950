<!-- <div class="footer-wrapper"> -->
  <div class="footer">
    <a
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="Path.CALENDAR"
      class="footer__link"
      routerLinkActive="footer__link--active"
    >
      <i class="icon-calendar"></i>
      <div class="footer__link-text">Журнал</div>
    </a>
    <a
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="Path.CLIENTS"
      class="footer__link"
      routerLinkActive="footer__link--active"
    >
      <i class="icon-id-card"></i>
      <div class="footer__link-text">Клієнти</div>
    </a>
    <a class="footer__central-menu-button"  (click)="goToNewAppointmentPage()">
      <button class="primary -large">
        <i class="icon-plus"></i>
      </button>
    </a>
    <a
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="Path.EMPLOYEES"
      class="footer__link"
      routerLinkActive="footer__link--active"
    >
    <i class="icon-people"></i>
      <div class="footer__link-text">
        Персонал
      </div>
    </a>
    <a
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="Path.MENU"
      class="footer__link"
      routerLinkActive="footer__link--active"
    >
      <i class="icon-menu"></i>
      <div class="footer__link-text">
        Меню
      </div>
    </a>
  <!-- </div> -->
</div>
