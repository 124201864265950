<div class="empty-state">
  <div class="empty-state__icon">
      <img src="../../../assets/img/illustrations/{{ icon }}.svg" alt="Empty state" />
  </div>
  <div class="empty-state__title" *ngIf="title">
    {{ title }}
  </div>
  <div class="empty-state__description" *ngIf="description">
    {{ description }}
  </div>
  <div class="empty-state__action" *ngIf="buttonTitle">
    <button class="primary -small" (click)="onClickButton.emit()">
      <i class="icon-{{ buttonIcon }}" *ngIf="hasButtonIcon"></i>
      <span>{{ buttonTitle }}</span>
    </button>
  </div>
</div>
