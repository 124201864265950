import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, Actions, ofActionSuccessful } from '@ngxs/store';
import { EmployeesActions } from 'src/app/pages/employees/state/employees-state.action';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-img-upload-dialog',
  templateUrl: './img-upload-dialog.component.html',
  styleUrls: ['./img-upload-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ImgUploadDialogComponent implements OnDestroy {
  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ImgUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      file: string,
      employeeId: string
    },
    private store: Store,
    private actions$: Actions
  ) { }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  uploadAvatar() {
    let myFormData = new FormData();
    myFormData.append('avatar', this.data.file);
    this.store.dispatch(new EmployeesActions.Edit({
      employeeId: this.data.employeeId,
      body: myFormData
    }));

    this.actions$.pipe(
      ofActionSuccessful(EmployeesActions.Edit),
      takeUntil(this.onDestroy$)
    ).subscribe((data) => {
      this.dialogRef.close(true);
    })

  }

  close() {
    this.dialogRef.close(false);
  }
}
