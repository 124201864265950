import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Path } from '../../app-routing.config';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(
    private router: Router
  ) {}

  goToMainPage() {
    this.router.navigate([Path.CALENDAR]);
  }

}
