<ng-container *ngIf="widgetLink">
  <div class="link-follow-button-group">
    <span class="title">Ваші посилання:</span>

    <div class="link-follow-button-list">
      <div class="link-follow-button">
        <div class="navigation-wrapper"  (click)="navigateToWidget()">
          <div class="icon-wrapper widget-link">
            <i class="icon-offices"></i>
          </div>

          <span class="title">Віджет онлайн запису</span>
        </div>

        <button class="copy-button default" (click)="copyWidgetLink()">
          <i class="icon-copy"></i>
        </button>
      </div>
      <div class="link-follow-button">
        <div class="navigation-wrapper"  (click)="navigateToTgBotEmployee()">
          <div class="icon-wrapper tg-employee-link">
            <i class="icon-telegram"></i>
          </div>

          <span class="title">Telegram-бот працівника</span>
        </div>

        <button class="copy-button default" (click)="copyTgBotEmployeeLink()">
          <i class="icon-copy"></i>
        </button>
      </div>
      <div class="link-follow-button">
        <div class="navigation-wrapper"  (click)="navigateToTgBotClient()">
          <div class="icon-wrapper tg-client-link">
            <i class="icon-telegram"></i>
          </div>

          <span class="title">Telegram-бот клієнта</span>
        </div>

        <button class="copy-button default" (click)="copyTgBotClientLink()">
          <i class="icon-copy"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>
